import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const ConsultPharmacist = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [fields, setFields] = useState({
    name: "",
    phone: "",
    remarks: "",
  });

  useEffect(() => {
    loadSlots();
  }, []);

  const loadSlots = async () => {
    try {
      const res = await API({
        method: "GET",
        url: "slots/",
      });
      setSlots(res);
      setDates([...new Set(res.map((slot) => slot.date))]);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(dates);

  const handleChange = (key, text) => {
    setFields({
      ...fields,
      [key]: text,
    });
  };

  const handleSubmit = async () => {
    if (
      fields.name.length === 0 ||
      fields.name === "" ||
      fields.phone.length === 0 ||
      fields.phone === ""
    ) {
      return alert("All fields marked with * are required and must be filled.");
    }
    if (fields.phone.length !== 11) {
      return alert("Phone number should be 11 digits");
    }
    if (fields.phone[0] !== "0") {
      return alert("Phone number should start with 0");
    }
    setLoading(true);
    try {
      const res = await API({
        method: "POST",
        url: "appointment/",
        requestConfig: {
          name: fields.name,
          phone: fields.phone,
          remark: fields.remarks,
          slot: selectedSlot.id,
          booked: true,
        },
      });
      setOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Container component="main" sx={{ mt: 1, mb: 1, minHeight: 250 }}>
      <Typography variant="h5" fontWeight={"bold"} mt={2}>
        Consult with Pharmacist
      </Typography>
      <Typography fontWeight={"bold"} mt={2} mb={2}>
        Select a date to view slots
      </Typography>
      <Grid container spacing={2}>
        {dates
          .filter(
            (item) =>
              new Date(item) >= new Date().setDate(new Date().getDate() - 1)
          )
          .map((date) => (
            <Grid item>
              <Button
                variant={selectedDate === date ? "contained" : "outlined"}
                key={date}
                style={{
                  margin: 5,
                  width: 100,
                  padding: 10,
                }}
                //   disabled={selectedDate === date}
                onClick={() => setSelectedDate(date)}
              >
                <Stack>
                  <Typography
                    color={selectedDate === date ? "primary.600" : "black"}
                    style={{
                      fontSize: 16,
                      textAlign: "center",
                    }}
                  >
                    {moment(date).format("ddd")}
                  </Typography>
                  <Typography
                    color={selectedDate === date ? "primary.600" : "black"}
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {moment(date).format("DD")}
                  </Typography>
                  <Typography
                    color={selectedDate === date ? "primary.600" : "black"}
                    style={{
                      fontSize: 16,
                      textAlign: "center",
                    }}
                  >
                    {moment(date).format("MMM")}
                  </Typography>
                </Stack>
              </Button>
            </Grid>
          ))}
      </Grid>
      {selectedDate && (
        <>
          <Typography fontWeight={"bold"} mt={2} mb={2}>
            Time Slots
          </Typography>
          {slots.map((slot) => {
            if (slot.date !== selectedDate) {
              return null;
            }
            return (
              <Button
                variant={selectedSlot === slot ? "contained" : "outlined"}
                key={slot.id}
                style={{
                  margin: 5,
                }}
                disabled={slot.booked}
                // isPressed={selectedSlot === slot}
                onClick={() => setSelectedSlot(slot)}
              >
                <Typography
                //   color={selectedSlot === slot ? "primary" : "black"}
                >
                  {moment(slot.start_time, "HH:mm:ss").format("hh:mm A")}
                </Typography>
              </Button>
            );
          })}
        </>
      )}
      {selectedSlot && (
        <Stack spacing={2}>
          <Typography fontWeight={"bold"} mt={2} mb={2}>
            Enter your details
          </Typography>
          <Stack direction={"row"} spacing={2}>
            <TextField
              type="text"
              placeholder="Name*"
              fullWidth
              onChange={(e) => handleChange("name", e.target.value)}
            />
            <TextField
              type="text"
              placeholder="Phone*"
              fullWidth
              onChange={(e) => handleChange("phone", e.target.value)}
            />
          </Stack>
          <TextField
            type="text"
            placeholder="Remarks"
            multiline
            rows={4}
            onChange={(e) => handleChange("remarks", e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 2,
            }}
          >
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleSubmit}
            >
              Book Slot
            </LoadingButton>
          </Box>
        </Stack>
      )}
      <Dialog open={open}>
        <DialogContent>
          <Alert>
            Your Appointment has been booked, Our Pharmacist will call you.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => navigate("/")}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ConsultPharmacist;
