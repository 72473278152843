import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Material UI
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
  IconButton,
  Modal,
  Breadcrumbs,
  Link,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CloseIcon from "@mui/icons-material/Close";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";

//Redux
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/cartAction";

//Components
import ProductContainer from "../../Components/Products/ProductContainer";

//Fetch
import axios from "axios";
import baseURL from "../../Assets/common/baseURL";

//Image
import EmptyLogo from "../../Assets/images/Emptylogo.png";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import API from "../../utils/API";

function DetailsPage(props) {
  const navigate = useNavigate();

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const params = useParams();

  const location = useLocation();

  const [product, setProduct] = useState({});
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isPack, setPack] = useState(false);
  const [activePrice, setActivePrice] = useState(product.price);

  const [similarProducts, setSimilarProducts] = useState([]);

  const [selectedImage, setSelectedImage] = useState(product.image);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [image404, setImage404] = useState({
    image1: true,
    image2: true,
  });

  const [selectedDescription, setSelectedDescription] = useState("description");

  //Fetch Similar Products
  useEffect(() => {
    loadData();
  }, [params]);

  useEffect(() => {
    setSelectedImage(product.image);
  }, [product]);

  const getInitialDescription = (_product) => {
    if (_product.descriptions_detail) {
      return "description";
    } else if (_product.descriptions_use) {
      return "howtouse";
    } else if (_product.descriptions_sideeffect) {
      return "sideeffects";
    } else if (_product.descriptions_precaution) {
      return "precautions";
    }
  }



  const loadData = async () => {
    const res = await API({
      method: "GET",
      url: `product/${params.id}/`,
    });
    setProduct(res);
    setSelectedDescription(getInitialDescription(res));
    const res1 = await API({
      method: "GET",
      url: `product/?generic_name=${res.generic_name}`,
    });
    setSimilarProducts(res1?.results);
  };

  console.log(selectedDescription);

  //Unit or Pack
  const changeType = (event, newType) => {
    if (newType !== null) {
      setPack(newType);
    }
  };

  useEffect(() => {
    isPack ? setActivePrice(product.packprice) : setActivePrice(product.price);
  }, [isPack]);

  //Images
  const imageURL1 = product.image;
  const imageURL2 = product.image_back;
  var images = [];
  if (imageURL1) {
    images.push(imageURL1);
  }
  if (imageURL2) {
    images.push(imageURL2);
  }

  const imageIndicatorArray = [
    <img
      src={imageURL1}
      height={100}
      width={100}
      style={{ borderRadius: 25 }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = EmptyLogo;
      }}
      onClick={() => setSelectedImage(imageURL1)}
    />,
    imageURL2 && (
      <img
        src={imageURL2}
        height={100}
        width={100}
        style={{ borderRadius: 25 }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = EmptyLogo;
        }}
        onClick={() => setSelectedImage(imageURL2)}
      />
    ),
  ];

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const style = {
    width: 400,
    height: 300,
    backgroundSize: "cover",
  };
  const config = {
    viewedImageSize: 0.8,
    backgroundOpacity: 0.6,
  };

  //Add to Cart
  const payloadData = {
    id: product.id,
    name: product.name,
    qty: quantity,
    image: product.image,
    unit_price: product.price_unit,
    qty_in_pack: product.pieces,
    prescription_required: product.prescription_required,
    price: isPack ? product.price_pack : product.price_unit,
    isPack,
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 5 }}>
      <Box sx={{ mt: 5, mb: 5 }}>
        <Breadcrumbs>
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>

          {location.state.breadcrumb && (
            <Link underline="hover" color="inherit">
              {location.state.breadcrumb}
            </Link>
          )}
          <Link underline="hover" color="inherit">
            {product.name}
          </Link>
        </Breadcrumbs>
        <Grid
          container
          spacing={2}
          sx={{
            mt: 1,
          }}
        >
          <Grid item xs={12} md={5}>
            {image404.image1 === true || image404.image2 === true ? (
              <div style={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 5,
                    right: 30,
                    zIndex: 100,
                  }}
                >
                  <IconButton onClick={() => openImageViewer(0)}>
                    <ZoomInIcon fontSize="large" color={"primary"} />
                  </IconButton>
                </Box>
                <Stack direction={"row"} spacing={2}>
                  <Stack spacing={2}>
                    {imageIndicatorArray.map((item, index) => item)}
                  </Stack>
                  <img
                    src={selectedImage}
                    height={"70%"}
                    width={"70%"}
                    style={{ borderRadius: 25 }}
                    alt={product?.name}
                  />
                </Stack>
                {/* <Carousel
                  animation={"slide"}
                  navButtonsAlwaysInvisible
                  autoPlay={false}
                  swipe={isMatch ? true : false}
                  cycleNavigation={isMatch ? true : false}
                  sx={{ borderRadius: 5 }}
                  indicators={true}
                  IndicatorIcon={imageIndicatorArray}
                  indicatorContainerProps={{}}
                  indicatorIconButtonProps={{
                    style: {
                      margin: "10px",
                      boderRadius: "15px",
                    },
                  }}
                  activeIndicatorIconButtonProps={{
                    style: {},
                  }}
                >
                  {images.map((item, index) => (
                   
                  ))}
                </Carousel> */}
              </div>
            ) : (
              <div>
                <img
                  src={EmptyLogo}
                  height={"100%"}
                  width={"100%"}
                  style={{ borderRadius: 25 }}
                />
              </div>
            )}

            <Modal open={isViewerOpen} onClose={closeImageViewer}>
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    height: isMatch ? 300 : 600,
                    width: isMatch ? 300 : 600,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                      zIndex: 100,
                    }}
                  >
                    <IconButton onClick={closeImageViewer}>
                      <CloseIcon color="primary" />
                    </IconButton>
                  </Box>
                  <ResponsiveCarousel
                    emulateTouch
                    showArrows
                    infiniteLoop
                    showThumbs={false}
                    stopOnHover={false}
                    renderArrowPrev={(prev) => {
                      return (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: 10,
                            zIndex: 101,
                          }}
                        >
                          <IconButton onClick={() => prev()}>
                            <ArrowBackIos color={"primary"} />
                          </IconButton>
                        </div>
                      );
                    }}
                    renderArrowNext={(next) => {
                      return (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: 10,
                          }}
                        >
                          <IconButton onClick={() => next()}>
                            <ArrowForwardIos color={"primary"} />
                          </IconButton>
                        </div>
                      );
                    }}
                  >
                    {images.map((item) => (
                      <img
                        src={item}
                        alt={item?.name}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = EmptyLogo;
                        }}
                      />
                    ))}
                  </ResponsiveCarousel>
                </div>
              </Box>
            </Modal>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box sx={{ m: 2 }}>
              <Typography variant={"h5"}>{product.name}</Typography>
              {product.prescription_required ? (
                <Typography color={"secondary"} fontWeight={"bold"}>
                  Prescription Required*
                </Typography>
              ) : null}
              {product.generic_name === null ? null : (
                <Typography variant={"p"} sx={{ fontSize: 12 }}>
                  <br /> Generic Name: {product.generic_name} <br />
                </Typography>
              )}
              {product.manufacturer === null ? null : (
                <Typography variant={"p"} sx={{ fontSize: 12 }}>
                  Manufacturer: {product.brand_name}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                padding: 2,
                margin: 2,
                borderRadius: 5,
                backgroundColor: "#fff",
              }}
            >
              <ToggleButtonGroup
                defaultValue={false}
                value={isPack}
                exclusive
                onChange={changeType}
                fullWidth
              >
                <ToggleButton color="primary" value={false}>
                  <Typography sx={{ fontWeight: "bold" }}>Unit</Typography>
                </ToggleButton>
                <ToggleButton color="secondary" value={true}>
                  <Typography sx={{ fontWeight: "bold" }}>Pack</Typography>
                </ToggleButton>
              </ToggleButtonGroup>

              <Box sx={{ p: 2 }}>
                <Typography
                  variant="h5"
                  color={"primary"}
                  sx={{ fontSize: 18 }}
                >
                  <b>Rs. {isPack ? product.price_pack : product.price_unit} </b>{" "}
                  {isPack &&
                    `/ ${product.pieces} Unit${
                      product.pieces !== 1 ? "s" : ""
                    } in each pack.`}
                </Typography>
                <Typography></Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Stack direction={"row"}>
                  <Button
                    small
                    onClick={() =>
                      quantity > 1 ? setQuantity(quantity - 1) : quantity
                    }
                  >
                    <RemoveIcon />
                  </Button>
                  <TextField
                    margin="Normal"
                    type={"number"}
                    value={quantity}
                    variant={"outlined"}
                    sx={{ width: 70 }}
                    onChange={(e) => setQuantity(e.target.valueAsNumber)}
                  />
                  <Button small onClick={() => setQuantity(quantity + 1)}>
                    <AddIcon />
                  </Button>
                </Stack>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleClick();
                    props.addItemToCart(payloadData);
                  }}
                >
                  Add to Cart
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Stack direction={"row"} spacing={2 } sx={{ mt: 2 }}>
          {product.descriptions_detail && (
            <Button
              variant={
                selectedDescription === "description" ? "contained" : "outlined"
              }
              style={{
                textTransform: "none",
              }}
              onClick={() => setSelectedDescription("description")}
            >
              Descriptions
            </Button>
          )}
          {product.descriptions_use && (
            <Button
              variant={
                selectedDescription === "howtouse" ? "contained" : "outlined"
              }
              style={{
                textTransform: "none",
              }}
              onClick={() => setSelectedDescription("howtouse")}
            >
              How to Use
            </Button>
          )}
          {product.descriptions_sideeffect && (
            <Button
              variant={
                selectedDescription === "sideeffects" ? "contained" : "outlined"
              }
              style={{
                textTransform: "none",
              }}
              onClick={() => setSelectedDescription("sideeffects")}
            >
              Side Effects
            </Button>
          )}
          {product.descriptions_precaution && (
            <Button
              variant={
                selectedDescription === "precautions" ? "contained" : "outlined"
              }
              style={{
                textTransform: "none",
              }}
              onClick={() => setSelectedDescription("precautions")}
            >
              Precautions
            </Button>
          )}
        </Stack>
        <Box
          sx={{
            mt: 2,
            padding: 2,
            borderRadius: 5,
          }}
        >
          {selectedDescription === "description" && (
            <div
              dangerouslySetInnerHTML={{
                __html: product.descriptions_detail
                  ? product.descriptions_detail
                  : "",
              }}
            ></div>
          )}
          {selectedDescription === "howtouse" && (
            <div
              dangerouslySetInnerHTML={{
                __html: product.descriptions_use
                  ? product.descriptions_use
                  : "",
              }}
            ></div>
          )}
          {selectedDescription === "sideeffects" && (
            <div
              dangerouslySetInnerHTML={{
                __html: product.descriptions_sideeffect
                  ? product.descriptions_sideeffect
                  : "",
              }}
            ></div>
          )}
          {selectedDescription === "precautions" && (
            <div
              dangerouslySetInnerHTML={{
                __html: product.descriptions_precaution
                  ? product.descriptions_precaution
                  : "",
              }}
            ></div>
          )}
        </Box>
        <ProductContainer
          heading={"Similar Products"}
          products={similarProducts}
        />
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Product has been Added to your Cart{" "}
          <Button
            size={"small"}
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={() => navigate("/Cart")}
          >
            View Cart
          </Button>
        </Alert>
      </Snackbar>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToCart: (data) => dispatch(actions.addToCart(data)),
  };
};

export default connect(null, mapDispatchToProps)(DetailsPage);
